@import '../../styles/base/variables';
@import '../../styles/mixins/mixins';

.card {
    $this: &;
    $_card-background-color: #fafafa;

    background-color: $_card-background-color;
    border-radius: 0.8rem;
    box-shadow: $box-shadow-base-l;
    box-sizing: border-box;
    position: relative;

    &--category {
        #{$this}__title {
            $_category-link-title-color: #31465e;

            color: $_category-link-title-color;
        }
    }

    a {
        align-items: center;
        display: flex;
        min-height: 9.6rem;
        padding: 0 0 0 1.6rem;
        text-decoration: none;
    }

    &__title {
        margin: 0;
        padding-right: 10rem; // Prevents title with two or more words slip under the icon
    }

    &__text {
        color: $color-text-400;
        margin: 0;
    }

    svg {
        height: 15rem;
        position: absolute;
        right: -3.6rem;
        top: -2.7rem;
        transition: transform 0.25s;
        width: 15rem;

        @include media-query-tablet-and-down {
            height: 13rem;
            right: -0.5rem;
            top: -1.7rem;
            width: 13rem;
        }

        @include media-query-phone {
            height: 12rem;
            right: 0;
            top: -1.2rem;
            width: 12rem;
        }
    }

    &:hover svg {
        transform: scale(1.03);
    }
}
