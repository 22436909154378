@import '../../styles/base/variables';

.review-card {
    background-color: $color-white;
    border-radius: 0.8rem;
    box-shadow: $box-shadow-base-sm;
    margin: 0 0 1.6rem;
    padding: 1.9rem 1.6rem;
    position: relative;

    &__rating {
        align-items: center;
        background-color: $color-energy-yellow;
        border-radius: 0 0.8rem;
        box-sizing: border-box;
        display: flex;
        height: 3.2rem;
        padding: 0 1rem 0 1.2rem;
        position: absolute;
        right: 0;
        top: 0;
        width: 6.5rem;
    }

    &__star-icon {
        margin: 0 0.8rem 0 0;
    }

    &__rating-value {
        font-size: $text-small;
        font-weight: $font-weight-bold;
        line-height: $line-height-xs;
    }

    &__author {
        font-size: $text-small;
        font-weight: $font-weight-bold;
        margin: 0 5.5rem 0.7rem 0;
        text-transform: uppercase;
    }

    &__text {
        font-size: $text-small;
        margin: 0;
    }
}
