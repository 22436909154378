@import '../../styles/base/variables';

.error-message {
    align-items: center;
    background-color: $color-error;
    border-radius: 0.5rem;
    color: $color-white;
    display: flex;
    padding: 1.6rem;

    &__text {
        font-size: $text-small;
        font-weight: $font-weight-bold;
        line-height: 1.14;
        margin: 0 auto 0 0.8rem;
    }

    &__close {
        cursor: pointer;
    }
}
