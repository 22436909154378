@import '../../styles/mixins/mixins';

.restaurants-widget {
    display: grid;
    gap: 4.8rem;
    grid-column: 1/3;
    grid-row: 3/4;
    grid-template-columns: repeat(3, minmax(100px, 1fr));

    @media screen and(max-width: 1023px) {
        grid-template-columns: unset;
        grid-template-rows: repeat(3, min-content);
    }
}
