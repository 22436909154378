@import '../styles/base/variables';

.not-found {
    &__image {
        border-radius: 0.8rem;
        display: block;
        margin: 0 auto;
        max-width: 50rem;
        width: 100%;
    }

    &__link {
        color: $color-text-700;
        display: block;
        font-size: $text-large;
        margin: 2rem auto;
        text-align: center;
        text-transform: uppercase;

        &:hover {
            color: $color-text-800;
        }
    }
}
