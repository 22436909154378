@import '../../styles/base/variables';

.addon {
    display: grid;
    grid-template-rows: repeat(3, min-content);
    margin-top: 1.7rem;
    row-gap: 1.7rem;

    svg {
        height: 1.8rem;
        transition: transform 0.25s;
        width: 1.8rem;
    }

    path {
        stroke: $color-fiord;
    }

    &__link-box {
        display: grid;
        gap: 0.8rem;
        grid-template-rows: repeat(2, min-content);
        justify-items: start;
    }

    &__link:link,
    &__link:visited {
        align-items: center;
        color: $color-fiord;
        cursor: pointer;
        display: flex;
        gap: 0.95rem;
        text-decoration: none;
    }

    &__web-page::after,
    &__address::after {
        background-color: $color-fiord;
        content: '';
        height: 0.2rem;
        position: absolute;
        right: 0;
        top: 1.5rem;
        transition: width 0.25s ease-out;
        width: 0;
    }

    &__link:link:hover &__web-page::after,
    &__link:link:hover &__address::after {
        left: 0;
        width: 100%;
    }

    &__link:link:hover svg,
    &__link:visited:hover svg {
        transform: scale(1.15);
    }

    &__address-wrap,
    &__webpage-wrap {
        -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
        display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
        -webkit-line-clamp: 1;
        line-clamp: 1;
        overflow: hidden;
        position: relative;
    }

    &__description {
        -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
        color: $color-slate-gray;
        display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
        height: 7.2rem;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        line-height: $line-height-xxl;
        overflow: hidden;
    }

    &__actions {
        align-items: center;
        display: flex;
        gap: 1.6rem;
        justify-self: end;
    }

    &__restaurant-page {
        color: $color-slate-gray;
        text-decoration: none;
        transition: color 0.25s;

        &:hover,
        &:focus {
            color: $color-midnight;
        }
    }
}
