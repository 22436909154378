@import '../../styles/mixins/mixins';
@import '../../styles/base/variables';

.restaurant-information {
    grid-column: 1/3;
    grid-row: 2/3;

    @include media-query-tablet-and-down {
        grid-column: 1/2;
    }

    &__title {
        margin: 0 0 2.4rem;
    }

    &__container {
        background: $color-white;
        box-shadow: $box-shadow-base-sm;
        display: flex;
        flex-wrap: wrap;
        gap: 2.9rem 0;
        padding: 2.4rem;

        @include media-query-phone {
            flex-direction: column;
            flex-wrap: unset;
            padding: 1.8rem;
        }
    }

    &__item {
        align-items: center;
        display: flex;
        gap: 1.2rem;
        width: 50%;

        @include media-query-phone {
            width: 100%;
        }

        svg {
            flex: 0 0 2.4rem;
            height: 2.4rem;
            width: 2.4rem;
        }
    }

    &__data {
        h3 {
            color: $color-text-700;
            margin: 0;
        }

        a {
            text-decoration: none;
        }

        p,
        a {
            color: $color-text-600;
            font-size: $text-small;
            font-weight: $font-weight-bold;
            line-height: 1.143;
            margin: 0.4rem 0 0;
            text-transform: uppercase;
        }
    }
}
