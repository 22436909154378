@import '../../styles/base/variables';

.social-actions {
    padding: 0 1.6rem;

    &__container {
        align-items: center;
        border-bottom: 1px solid $color-border-400;
        display: flex;
        flex-wrap: wrap;
        min-height: 3.2rem;
        padding: 0.8rem 0;

        &:last-child {
            border-bottom: 0;
        }
    }
}
