@import '../../styles/base/variables';
@import '../../styles/mixins/mixins';

.hello-widget {
    align-items: end;
    display: grid;
    grid-column: 1/2;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, min-content);
    justify-items: start;
    row-gap: 1.6rem;

    @include media-query-tablet {
        gap: unset;
    }

    @include media-query-phone {
        gap: unset;
        grid-column: 1/2;
    }

    &__time {
        $_time-size-desktop: 10rem;
        color: $color-text-700;
        font-size: $_time-size-desktop;
        font-weight: 700;
        line-height: 1.17;
        padding-bottom: 0.7rem;

        @include media-query-tablet {
            $_time-size-tablet: 7.5rem;
            font-size: $_time-size-tablet;
        }

        @include media-query-phone {
            $_time-size-phone: 4.5rem;
            font-size: $_time-size-phone;
        }
    }

    &__greetings {
        color: $color-text-700;
        margin: 0;
        padding-bottom: 0.3rem;

        @include media-query-tablet {
            font-size: $heading-font-size-3;
        }

        @include media-query-phone {
            font-size: $heading-font-size-4;
        }
    }

    &__user {
        text-transform: uppercase;
        white-space: nowrap;
    }
}
