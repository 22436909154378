@import '../../styles/base/variables';

.tag-card {
    background-color: $color-midnight;
    border-radius: 0.8rem;
    color: $color-white;
    font-size: $text-xsmall;
    line-height: 1.333;
    padding: 0.6rem 1.2rem;
    width: fit-content;
}
