@import '../../styles/base/variables';
@import '../../styles/mixins/mixins';

.browse-restaurants-card {
    background-color: $card-background;
    border-radius: 0.8rem;
    box-shadow: $box-shadow-base-sm;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 22.9rem;
    justify-content: center;
    padding: 5.95rem 5.05rem;

    &__title {
        color: $color-darken-70;
        line-height: $line-height-l;
        margin: 0 0 2.1rem;
        text-align: center;
    }

    a {
        @include action-transition;

        align-self: center;
        background-color: $color-primary;
        border-radius: 6.5rem;
        color: $color-white;
        padding: 0.9rem 1.6rem;
        text-decoration: none;
    }
}
