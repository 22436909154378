@import '../../styles/base/variables';
@import '../../styles/mixins/mixins';

.button {
    $_button-border-radius: 6.5rem;
    $_button-color-hover: #5836cc;
    $_button-color-active: #462ca3;

    align-items: center;
    background-color: $color-primary;
    border: 0;
    border-radius: $_button-border-radius;
    color: $color-white;
    cursor: pointer;
    display: flex;
    font-weight: $font-weight-medium;

    &--medium {
        font-size: $text-xsmall;
        line-height: $line-height-xxl; // 16/12
        padding: 0.9rem 1.6rem;
    }

    &--large {
        font-size: $text-small;
        line-height: 1.7143; // 24/14
        padding: 0.8rem 2.4rem;
    }

    &--disabled {
        background-color: $color-porcelain-200;
        color: $color-gray-chateau;
        cursor: unset;
    }

    &__icon {
        $_icon-size: 1.6rem;

        height: $_icon-size;
        width: $_icon-size;
    }

    &--medium & {
        &__icon {
            padding-right: 0.4rem;
        }
    }

    &--large & {
        &__icon {
            padding-right: 2rem;
        }
    }

    // change 'button__icon' stroke when 'button--disabled'
    &--disabled & {
        &__icon {
            path {
                stroke: $color-gray-chateau;
            }
        }
    }

    &:not(&--disabled) {
        @include action-transition;

        &:hover {
            background-color: $_button-color-hover;
        }

        &:active {
            background-color: $_button-color-active;
        }
    }
}
