@import '../styles/mixins/mixins';

.category {
    @mixin grid {
        display: grid;
        grid-auto-rows: auto;
        grid-template-columns: none;
    }

    margin-bottom: 7.6rem;

    @include media-query-phone {
        margin-bottom: 3.6rem;
    }

    &__header {
        margin: 0.8rem 0 7.2rem;

        @include media-query-phone {
            margin-bottom: 3.6rem;
        }
    }

    &__name {
        text-transform: uppercase;
    }

    &__grid {
        display: grid;
        gap: 3.2rem;

        &--1 {
            grid-template-columns: 42.6rem;

            @include media-query-phone {
                @include grid;
            }
        }

        &--2 {
            grid-template-columns: repeat(2, minmax(32rem, 42.6rem));

            @include media-query-phone {
                @include grid;
            }
        }

        &--autofit {
            grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));

            @include media-query-phone {
                @include grid;
            }
        }
    }
}
