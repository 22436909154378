@import '../../styles/base/variables';
@import '../../styles/mixins/';

.newsfeed {
    grid-column: 1/3;

    @include media-query-phone() {
        grid-column: 1/2;
    }

    &__header {
        color: $color-text-700;
        margin: 0 0 2.4rem;
    }

    &__layout {
        display: flex;
        gap: 4.8rem;
    }

    &__item {
        padding-bottom: 4.8rem;
        width: 100%;
    }
}
