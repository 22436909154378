@import '../../styles/base/variables';

$_triangle-border: transparent transparent $color-border-500 transparent;
$_triangle-fill: transparent transparent $color-white transparent;

.user-profile-menu {
    list-style: none;
    min-width: 200px;
    position: absolute;
    right: 0;

    &::before,
    &::after {
        border-style: solid;
        bottom: 100%;
        content: '';
        position: absolute;
    }

    &::after {
        border-color: $_triangle-fill;
        border-width: 5px;
        right: 15px;
        top: -9px;
    }

    &::before {
        border-color: $_triangle-border;
        border-width: 7px;
        right: 13px;
        top: -13px;
    }

    &__item {
        background: $color-white;
        border: 1px solid $color-border-500;
        border-style: none solid;
        color: $color-text-700;
        font-size: $text-small;
        line-height: 22px;
        transition: background-color 0.25s;

        a {
            align-items: center;
            color: $color-text-700;
            display: flex;
            gap: 8px;
            height: 100%;
            overflow-x: hidden;
            padding: 13px 16px;
            text-decoration: none;

            &:visited {
                color: $color-text-700;
            }
        }

        &:first-child {
            border-radius: 5px 5px 0 0;
            border-style: solid solid none;
        }

        &:last-child {
            border-radius: 0 0 5px 5px;
            border-style: none solid solid;
        }

        &:hover {
            background-color: $color-porcelain-200;
            cursor: pointer;
        }
    }
}
