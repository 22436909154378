@import '../../styles/base/variables';

.breadcrumbs {
    display: inline-flex;
    min-height: 18px;

    &__list {
        display: inline-flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
    }

    &__item {
        align-items: center;
        display: inline-flex;
    }

    &__item + &__item::before {
        background-image: url('../../assets/right.svg');
        background-size: 10px 10px;
        content: '';
        display: inline-block;
        height: 10px;
        margin: 0 4px;
        width: 10px;
    }

    &__link {
        color: $color-gray-chateau;
        font-size: $text-xsmall;
        font-weight: 500;
        line-height: 150%;
        text-decoration: none;
        text-transform: uppercase;

        &--current {
            color: $color-slate-gray;
        }
    }
}
