@import '../../styles/base/variables';

.controls {
    path {
        transition: stroke 0.2s, fill 0.2s;
    }

    &__btn {
        background-color: $color-white;
        border: 1px solid $color-border-400;
        cursor: pointer;
        line-height: 0;
        padding: 0.6rem 0.85rem;
        transition: background-color 0.25s;

        &--prev {
            border-radius: 0.5rem 0 0 0.5rem;
        }

        &--next {
            border-radius: 0 0.5rem 0.5rem 0;
            margin-left: -0.1rem;
        }

        &:hover {
            background-color: $color-gray-chateau;
        }

        &:hover path {
            fill: $color-white;
            stroke: $color-gray-chateau;
        }

        &:active {
            background-color: $color-fiord;
        }

        &:active path {
            stroke: $color-fiord;
        }
    }
}
