@import '../../styles/base/variables';
@import '../../styles/mixins/mixins';

.slider {
    border-radius: 0.8rem;
    box-shadow: $box-shadow-base-sm;
    height: 31.6rem;
    overflow: hidden;
    position: relative;
    width: 100%;

    &__controls {
        $_z-index: 200;

        display: flex;
        justify-content: space-between;
        position: absolute;
        right: 0;
        top: 2.4rem;
        width: 30%;
        z-index: $_z-index;

        @include media-query-phone {
            width: 100%;
        }
    }

    &__arrows {
        padding-right: 2.4rem;

        @include media-query-phone {
            padding-right: 0.8rem;
        }
    }

    &__content {
        display: flex;
        height: 31.6rem;
    }
}

.transition {
    transition: transform 0.5s;
}
