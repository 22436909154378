.user-profile-icon {
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: inherit;
    line-height: 0;
    padding: 0;
    width: inherit;

    &__photo {
        border-radius: inherit;
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &__no-photo {
        margin: auto;
    }
}
