@import 'colors';

// File for globaly used variables
// Variables naming convention used - "component-element-modifier" (with hyphens). If
// modifiers are numbers, they indicate the degree or intensity - 200 is the lightest and
// 900 is the darkest.

// Colors
$main-background: $color-porcelain-100;
$card-background: $color-white;

$color-primary: $color-electric-violet;

$color-border-400: $color-iron;
$color-border-500: $color-ghost;
$color-border-700: $color-slate-gray;
$color-border-800: $color-fiord;

$color-text-300: $color-heather;
$color-text-400: $color-pale-sky;
$color-text-600: $color-slate-gray;
$color-text-700: $color-fiord;
$color-text-800: $color-mirage;

$color-success: $color-magic-mint;
$color-info: $color-malibu;
$color-warning: $color-energy-yellow;
$color-error: $color-wild-watermelon;

// Shadows
$box-shadow-base-sm: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
$box-shadow-base-m: 0 0 4px 0 rgba(0, 0, 0, 0.2);
$box-shadow-base-l: 0 8px 23px 0 rgba(0, 0, 0, 0.25);

// Darken colors
$color-darken-20: rgba(0, 0, 0, 0.2);
$color-darken-40: rgba(0, 0, 0, 0.4);
$color-darken-70: rgba(0, 0, 0, 0.7);

// Fonts
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;

$heading-font-size-1: 3.6rem; // 36px
$heading-font-size-2: 3.2rem; // 32px
$heading-font-size-3: 2.4rem; // 24px
$heading-font-size-4: 2rem; // 20px
$heading-font-size-5: 1.8rem; // 18px
$heading-font-size-6: 1.6rem; // 16px

$text-large: 2rem; // 20px
$text-medium: 1.6rem; // 16px
$text-small: 1.4rem; // 14px
$text-xsmall: 1.2rem; // 12px
$text-micro: 1rem; // 10px

// Line heights

$line-height-xs: 1.143;
$line-height-s: 1.1667;
$line-height-m: 1.2;
$line-height-l: 1.334;
$line-height-xl: 1.43;
$line-height-xxl: 1.5;
