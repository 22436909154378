@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

$font-family-base: 'Roboto', sans-serif;
$font-family-alternative: 'Roboto Condensed', sans-serif;
$font-family-monospace: monospace, monospace;

$headline-text-transform: uppercase;
$body-text-transform: uppercase;

// Headings

h1 {
    font-size: $heading-font-size-1;
    font-weight: $font-weight-bold;
    line-height: $line-height-s;
}

h2 {
    font-size: $heading-font-size-2;
    font-weight: $font-weight-medium;
    line-height: $line-height-s;
    text-transform: $headline-text-transform;

    &.alt {
        font-size: $heading-font-size-3;
        font-weight: $font-weight-black;
        line-height: $line-height-l;
        text-transform: none;
    }
}

h3 {
    font-size: $heading-font-size-3;
    font-weight: $font-weight-medium;
    line-height: $line-height-s;

    &.alt {
        font-size: $heading-font-size-4;
        line-height: $line-height-m;
    }
}

h4 {
    font-size: $heading-font-size-4;
    font-weight: $font-weight-medium;
    line-height: $line-height-s;
    text-transform: $headline-text-transform;

    &.alt {
        font-size: $heading-font-size-6;
        line-height: $line-height-xxl;
        text-transform: none;
    }
}

h5 {
    font-size: $heading-font-size-5;
    font-weight: $font-weight-regular;
    line-height: $line-height-s;
    text-transform: $headline-text-transform;
}

// Body

p {
    font-size: $text-medium;
    font-weight: $font-weight-regular;
    line-height: $line-height-xxl;

    &.alt {
        font-size: $text-small;
        font-weight: $font-weight-medium;
        line-height: $line-height-xl;
    }
}

// Caption

.caption {
    font-size: $text-small;
    font-weight: $font-weight-medium;
    line-height: $line-height-xs;

    &-two {
        font-size: $text-xsmall;
        font-weight: $font-weight-medium;
        line-height: $line-height-xxl;
        text-transform: $body-text-transform;
    }
}

// Tiny

.tiny {
    font-size: $text-micro;
    font-weight: $font-weight-medium;
    line-height: $line-height-xxl;
    text-transform: $body-text-transform;

    &-two {
        font-size: $text-small;
        font-weight: $font-weight-regular;
        line-height: $line-height-l;
        text-transform: none;
    }
}
