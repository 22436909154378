@import '../../styles/mixins/mixins';

.categories {
    padding: 0 3.6rem 0 0;

    @include media-query-tablet-and-down {
        padding: 0 0.5rem 0 0;
    }

    @include media-query-phone {
        padding: 0;
    }

    &__title {
        margin: 0 0 4.2rem;

        @include media-query-tablet-and-down {
            margin: 0 0 2.5rem;
        }
    }

    &__list {
        display: grid;
        gap: 6.4rem 8.5rem;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        list-style: none;
        margin: 0;
        padding: 0;

        @include media-query-tablet-and-down {
            gap: 4.5rem 6rem;
        }

        @include media-query-phone {
            gap: 3.4rem 3rem;
        }
    }
}
