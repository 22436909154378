@import '../../styles/base/variables';
@import '../../styles/mixins/mixins';

@mixin flex-center {
    align-items: center;
    display: flex;
}

.restaurant-action-bar {
    align-items: center;
    background: $card-background;
    border-radius: 0.5rem;
    box-shadow: $box-shadow-base-sm;
    box-sizing: border-box;
    display: grid;
    grid-column: 1/4;
    grid-row: 1/2;
    grid-template-columns: repeat(3, 1fr);
    height: 5.9rem;
    padding: 0 1.6rem;

    @include media-query-tablet-and-down {
        grid-column: 1/2;
        grid-row: 1/2;
    }

    @include media-query-phone {
        gap: 1rem;
        grid-template-columns: repeat(2, 1fr);
        height: auto;
        padding: 1rem 1.6rem;
    }

    &__evaluate-restaurant {
        @include flex-center();
        gap: 1.9rem;

        @include media-query-phone {
            grid-row: 2;
        }
    }

    &__group-size {
        color: $color-border-800;
        font-size: $text-small;
        font-weight: $font-weight-bold;
        line-height: $line-height-xs;
        margin: 0;
        text-align: center;
        text-transform: uppercase;

        @include media-query-phone {
            grid-column: span 2;
        }
    }

    &__check-in {
        @include flex-center();
        gap: 1.6rem;
        justify-content: right;
    }
}
