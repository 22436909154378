@import '../styles/mixins';

.login {
    form {
        display: flex;
        flex-direction: column;
        row-gap: 2.4rem;
    }

    footer {
        display: flex;
        gap: 2rem;
        justify-content: space-between;
        text-align: right;

        a {
            text-decoration: none;
        }
    }

    &__field {
        min-width: 48rem;

        @include media-query-phone() {
            min-width: 23.1rem;
        }
    }
}
