@import '../../styles/base/variables';

.card-header {
    background-color: $card-background;
    border-radius: 8px 8px 0 0;
    color: $color-midnight;
    display: flex;
    font-size: $text-xsmall;
    height: 48px;
    justify-content: space-between;
    padding: 0 16px;
    position: relative;

    &__inner {
        align-items: center;
        display: flex;
        flex-grow: 1;
        text-align: center;
    }

    &__content {
        align-items: center;
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        margin-left: 12px;
    }

    &__avatar {
        flex-shrink: 0;
        height: 30px;
        width: 30px;
    }

    &__name {
        font-weight: $font-weight-medium;
        padding-right: 12px;
        text-transform: uppercase;
    }

    &__metadata {
        display: flex;
        gap: 8px;
    }

    &__location,
    &__time {
        color: $color-slate-gray;
        font-weight: $font-weight-medium;
    }
}
