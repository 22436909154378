.user-profile-widget {
    $this: &;

    border-radius: 50%;
    height: 40px;
    line-height: 0;
    position: relative;
    width: 40px;

    &.is-active {
        #{$this}__chevron {
            transform: rotate(180deg);
        }
    }

    &__chevron {
        bottom: 9px;
        float: right;
        left: 1px;
        position: relative;
        transition: transform 0.25s;
    }
}
