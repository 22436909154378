@import '../styles/mixins/mixins';

.dashboard {
    display: grid;
    grid-row-gap: 7.2rem;
    grid-template-columns: 1fr 1fr;

    @include media-query-phone() {
        grid-row-gap: 5rem;
        grid-template-columns: 1fr;
    }
}
