// Color definitions extracted from project design with names generated using
// Name that color @see http://chir.ag/projects/name-that-color/
// If numbers are added, they indicate the degree or intensity - 100 is the
// lightest and 900 is the darkest.

// General colors
$color-black: #000;
$color-white: #fff;

// App specific colors
$color-porcelain-100: #f6f7f8;
$color-porcelain-200: #edeff1;
$color-iron: #dcdfe3;
$color-ghost: #cacfd5;
$color-gray-chateau: #9ea7b3;
$color-geyser: #d3dce6;
$color-slate-gray: #6a7889;
$color-fiord: #3e5067;
$color-midnight: #001836;
$color-electric-violet: #6e44ff;
$color-magic-mint: #7bf1a8;
$color-wild-watermelon: #ff6e75;
$color-malibu: #72ddf7;
$color-energy-yellow: #fadb5e;
$color-mauve: #b892ff;
$color-heather: #c2cedb;
$color-mirage: #1f2d3d;
$color-purple-heart: #613ce0;
$color-pale-sky: #627485;
$color-mercury: #e5e5e5;
