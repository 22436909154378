@import '../../styles/mixins/mixins';

.slider-container {
    margin: 0 -1rem;
    overflow: hidden;
    padding: 0 1rem;

    &__header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.4rem;
    }

    &__heading {
        margin: 0;
    }

    &__content {
        display: flex;
        gap: 4.8rem;
        margin: 0 -1rem;
        overflow: hidden;
        padding: 1rem 0;
        scroll-snap-type: x;
    }

    &__wrapper {
        flex-shrink: 0;
        padding: 0 1rem;
        scroll-snap-align: end;
        width: calc((100% - 7.5rem * 3) / 4);

        // if slide breakpoints have to be at different points change values at _mixins.scss
        @include three-slide-screen {
            width: calc((100% - 7.8rem * 2) / 3);
        }
        @include two-slide-screen {
            width: calc((100% - 8.9rem) / 2);
        }
        @include one-slide-screen {
            width: calc(100% - 2rem);
        }
    }
}
