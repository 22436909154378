@import '../../styles/base/variables';
@import '../../styles/mixins/mixins';

.bday-card {
    background-color: $card-background;
    border-radius: 0.8rem;
    box-shadow: $box-shadow-base-sm;
    color: $color-midnight;
    margin-top: 4.8rem;
    position: relative;
    width: 100%;

    &__content {
        border-bottom: 1px solid $color-geyser; // divider
        margin: 0 1.6rem;
        padding: 1.6rem;

        @include media-query-phone {
            padding: 1.6rem 0;
        }
    }

    &__decoration {
        display: flex;
        justify-content: space-between;
    }

    &__description {
        align-self: center;
        min-width: 11rem; // for extremely long content
        padding: 0 0.5rem;
        text-align: center;
        word-wrap: break-word; // for extremely long content

        p {
            font-size: $text-xsmall;
            line-height: $line-height-l;
            margin: 0;
        }
    }

    svg {
        flex-shrink: 0;
    }

    &__photo {
        height: 9.6rem;
        left: 50%;
        margin-left: -4.8rem;
        position: absolute;
        top: -4.8rem;
        width: 9.6rem;
    }
}
