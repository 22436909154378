@import '../../styles/base/variables';
@import '../../styles/mixins/mixins';

.restaurant-hero-section {
    $_z-index: -1;
    height: 54rem;
    margin-left: -4.8rem;
    margin-top: -6.8rem;
    position: relative;
    width: calc(100%+4.8rem);
    z-index: $_z-index;

    @include media-query-phone {
        height: 36rem;
        margin-left: -1rem;
    }

    &__bg {
        border-radius: inherit;
        height: inherit;
        position: absolute;
        width: calc(100% + 2.4rem);

        @include media-query-phone {
            width: calc(100% + 0.5rem);
        }

        img {
            height: 100%;
            object-fit: cover;
            width: inherit;
        }
    }

    &__content {
        $_z-index: 1;
        align-content: flex-end;
        bottom: 0;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        padding: 0 4.8rem 7.7rem;
        position: absolute;
        width: calc(100% - 4.8rem);
        width: inherit;
        z-index: $_z-index;

        @include media-query-tablet {
            width: calc(100% - 4.8rem);
        }

        @include media-query-phone {
            bottom: 10%;
            padding: 0 2.4rem 2rem;
            width: calc(100% - 3.8rem);
        }
    }

    &__tags {
        display: flex;
        gap: 1.5rem;
    }

    &__title {
        color: $color-midnight;
        line-height: 1.166;
        margin: 0;

        @include media-query-phone {
            font-size: $heading-font-size-3;
        }
    }

    &::after {
        $color-background: rgba(255, 255, 255, 0);
        background-image: linear-gradient(
                90deg,
                $color-white 0%,
                $color-background 50%
            ),
            linear-gradient(270deg, $color-white 0%, $color-background 50%);
        border-radius: inherit;
        content: '';
        display: block;
        height: inherit;
        position: absolute;
        width: calc(100% + 4.8rem);

        @include media-query-phone {
            width: calc(100% + 1rem);
        }
    }
}
