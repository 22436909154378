@import '../../styles/base/variables';

.reservation-card {
    align-items: center;
    background-color: $card-background;
    border-radius: 8px;
    box-shadow: $box-shadow-base-l;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 0;
    justify-content: space-between;
    max-height: 94px;
    padding: 0 32px 0 16px;
    text-decoration: none;

    &__content {
        margin-top: 6px; // because the content block is not actually centered in figma
    }

    &__title {
        color: $color-fiord;
        margin: 0;
    }

    &__quantity {
        color: $color-pale-sky;
        white-space: nowrap;
    }

    &__decoration {
        margin-top: 6px;
        transition: transform 0.25s;
    }

    &:hover &__decoration {
        transform: scale(1.03);
    }
}
