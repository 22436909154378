@import '../../styles/base/variables';

.check-in-card {
    $_card-background-color: #31465e;
    align-items: center;
    background-color: $_card-background-color;
    border-radius: 0.8rem;
    display: flex;
    padding: 0.8rem 1.2rem;
    width: fit-content;

    &__icon {
        display: flex;
        padding: 0 0.8rem 0 0;
    }

    &__value {
        color: $color-energy-yellow;
        font-weight: $font-weight-bold;
    }
}
