@import '../../styles/base/variables';

.comments-list {
    color: $color-text-700;
    width: 100%;

    &__see-more-btn {
        background: none;
        border: 0;
        color: $color-text-600;
        cursor: pointer;
        font-size: $text-micro;
        line-height: $line-height-xxl;
        margin: 0 0 0.8rem;
        padding: 0;
    }

    &__content {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}
