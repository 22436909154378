@import '../../styles/base/variables';

.form-field {
    &__label,
    &__input {
        box-sizing: border-box;
        display: block;
        font-size: $text-small;
    }

    &__label {
        color: $color-gray-chateau;
        font-weight: $font-weight-bold;
        line-height: 1.143;
        padding-bottom: 0.8rem;
        text-transform: uppercase;

        &--disabled {
            color: $color-iron;
        }
    }

    &__input {
        border: 1px solid $color-ghost;
        border-radius: 0.5rem;
        color: $color-text-700;
        height: 4.8rem;
        padding: 1.3rem 1.6rem;
        width: 100%;

        &:focus {
            border-color: $color-border-800;
        }

        &:disabled {
            border-color: $color-border-400;
            color: $color-iron;
        }

        &::placeholder {
            color: $color-ghost;
            font-weight: $font-weight-regular;
            line-height: 1.57;
        }

        &--error {
            border-color: $color-error;
        }
    }

    &__error {
        color: $color-error;
        margin-top: 0.8rem;
    }
}
