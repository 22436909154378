@import '../../styles/base/variables';
@import '../../styles/mixins/mixins';

.restaurant-location {
    grid-column: 1/3;
    grid-row: 3/4;
    height: 100%;
    width: 100%;

    @include media-query-tablet-and-down {
        grid-column: 1/2;
    }

    &__title {
        margin: 0 0 2.4rem;
    }

    &__map {
        border: unset;
        border-radius: 0.8rem;
        box-shadow: $box-shadow-base-sm;
        min-height: 40rem;
        width: 100%;
    }
}
