@import '../../styles/base/variables';

.rating-card {
    align-items: center;
    background-color: $color-energy-yellow;
    border-radius: 0.8rem;
    display: flex;
    height: fit-content;
    padding: 0.8rem 1.2rem;
    width: fit-content;

    &:hover :not(:nth-child(1)) {
        display: flex;
    }

    &__icon {
        background: none;
        border: 0;
        cursor: pointer;
        display: flex;
        margin: 0;
        padding: 0 0.8rem 0 0;

        &:not(:nth-child(1)) {
            display: none;
        }
    }

    &__rate {
        font-weight: $font-weight-bold;
    }
}
