@import '../mixins/mixins';

.app {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1920px;
    min-height: 100vh;
}

.header,
.footer,
.container {
    padding: 0 48px;

    // minimize content padding for mobile view
    @include media-query-phone() {
        padding: 0 10px;
    }
}

.header,
.footer {
    box-shadow: $box-shadow-base-m;
}

.header {
    align-items: center;
    background-color: $color-primary;
    color: $main-background;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr auto 1fr;
    height: 80px;
    justify-content: center;
    margin: 0 0 48px;
    position: sticky;
    top: 0;
    z-index: map-get($z-index-values, header);

    @include media-query-phone() {
        grid-template-columns: 1fr 1fr auto;
        height: 50px;
    }

    .logo {
        display: flex;
        justify-self: left;

        &.active {
            $_logo-active-background-color: unset;
            background-color: $_logo-active-background-color;
        }

        svg {
            height: 48px;

            @include media-query-phone() {
                height: 38px;
            }
        }
    }

    .navigation {
        @include media-query-phone() {
            justify-self: right;
        }
    }

    .user-profile-widget {
        justify-self: right;

        @include media-query-phone() {
            height: 32px;
            width: 32px;
        }
    }
}

.container,
.footer {
    color: $color-text-700;
}

.footer {
    align-items: center;
    background-color: $color-white;
    display: flex;
    font-size: $text-micro;
    font-weight: 500;
    height: 72px;
    justify-content: right;
    margin-top: auto;
    text-transform: uppercase;
}
