@import '../styles/mixins';

.register {
    form {
        display: grid;
        grid-column-gap: 2rem;
        grid-template-columns: 1fr 1fr;
        row-gap: 2.4rem;

        @include media-query-phone() {
            grid-template-columns: 1fr;
        }
    }

    footer {
        display: flex;
        gap: 2rem;
        justify-content: space-between;
        text-align: right;

        a {
            text-decoration: none;
        }
    }

    &__field {
        min-width: 23.1rem;

        &--long {
            grid-column: span 2;

            @include media-query-phone() {
                grid-column: unset;
            }
        }
    }
}
