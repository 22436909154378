@import '../../styles/base/variables';
@import '../../styles/mixins/mixins';

.navigation {
    height: 100%;

    &__list {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        margin: 0;
        padding: 0;

        @include media-query-phone() {
            background-color: $color-primary;
            display: none;
            flex-direction: column;
            height: unset;
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 100%;

            &--open-on-mobile {
                box-shadow: $box-shadow-base-m;
                display: flex;
                opacity: 1;
            }
        }
    }

    &__element {
        height: 100%;
        list-style: none;

        @include media-query-phone() {
            height: 40px;
            width: 100%;
        }

        &:hover {
            background-color: $color-darken-20;
        }

        a {
            align-items: center;
            color: $main-background;
            display: flex;
            gap: 10px;
            height: 100%;
            justify-content: center;
            padding: 0 25px;
            text-decoration: none;
        }
    }

    &__toggle-mobile-menu {
        align-items: center;
        background: transparent;
        border: unset;
        display: none;

        @include media-query-phone() {
            cursor: pointer;
            display: flex;
            height: 100%;

            svg {
                fill: $color-white;
                height: 75%;
            }
        }
    }
}
