@import '../../styles/base/variables';

.restaurant-card {
    $_restaurant-card-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.25);
    background: $card-background;
    border-radius: 0.8rem;
    display: flex;
    filter: drop-shadow($_restaurant-card-shadow);
    flex-direction: column;
    justify-content: space-between;
    padding: 1.6rem;

    &__top-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__bottom-section {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-top: 10.6rem;

        &--expanded {
            margin-top: 15.2rem;
        }
    }

    &__data {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        width: 100%;
    }

    &__categories {
        display: flex;
        flex-direction: row;
        gap: 1.2rem;
    }

    &__category {
        color: $color-slate-gray;
        font-style: normal;
        font-weight: $font-weight-medium;
        line-height: 1;
        margin: 0;
        text-decoration: none;
        text-transform: uppercase;

        &:not(:first-child)::before {
            content: '•';
            padding-right: 1.2rem;
        }
    }

    &__title {
        text-decoration: none;

        &:link,
        &:visited {
            color: $color-midnight;
        }

        h3 {
            -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
            display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
            letter-spacing: 0.05rem;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            line-height: 1.167;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__time {
        color: $color-slate-gray;
        font-style: normal;
        font-weight: $font-weight-medium;
        line-height: 1;
        margin: 0;
    }

    &__bg {
        $_z-index: -1;
        border-radius: 0.8rem;
        height: 18rem;
        margin: -1.6rem;
        position: absolute;
        width: 100%;
        z-index: $_z-index;

        img {
            border-radius: 0.8rem 0.8rem 0 0;
            height: 100%;
            object-fit: cover;
            width: 100%;
        }

        &::after {
            $color-background: rgba(255, 255, 255, 0);
            background-image: linear-gradient(
                360deg,
                $color-white 0%,
                $color-background 100%
            );
            bottom: 100%;
            content: '';
            display: block;
            height: inherit;
            position: relative;
        }
    }
}
