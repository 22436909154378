@import '../../styles/mixins/mixins';

.similar-restaurants {
    grid-column: 1/4;
    grid-row: 4/5;
    width: 100%;

    @include media-query-tablet-and-down {
        grid-row: 5/6;
    }
}
