@import '../styles/mixins/mixins';

.eat-out {
    &__header {
        margin: 0.8rem 0 0;
    }

    &__content {
        display: grid;
        grid-template-rows: repeat(4, min-content);
        margin: 7.2rem 0 10.1rem;
        row-gap: 7.2rem;

        @include media-query-phone {
            margin: 3.6rem 0 5rem;
            row-gap: 3.6rem;
        }
    }
}
