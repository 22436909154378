@import '../../styles/mixins/mixins';

.reservations-widget {
    grid-column: 1/3;

    @include media-query-phone() {
        grid-column: 1/2;
    }

    &__title {
        margin: 0 0 24px;
    }

    &__container {
        display: flex;
        gap: 48px;
        padding: 24px 0;

        @media screen and(max-width: 1023px) {
            flex-direction: column;
        }
    }
}
