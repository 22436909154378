@import '../../styles/base/variables';
@import '../../styles/mixins/mixins';

.slide {
    background-color: $color-white;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 1fr;
    height: 100%;
    width: 100%;

    &__photo-wrapper {
        grid-column: 1/2;
        line-height: 0;
        width: 100%;

        @include media-query-phone {
            grid-column: 1/3;
            grid-row: 1/2;
        }

        &::after {
            $color-background: rgba(255, 255, 255, 0);

            background-image: linear-gradient(
                270deg,
                $color-white -0.71%,
                $color-background 99.41%
            );
            bottom: 100%;
            content: '';
            display: block;
            height: 100%;
            left: 0.2rem;
            position: relative;
            width: 100%;

            @include media-query-phone {
                background-image: unset;
            }
        }
    }

    &__photo {
        height: 31.6rem;
        object-fit: cover;
        width: 100%;

        @include media-query-phone {
            opacity: 0.25;
        }
    }

    &__content {
        background-color: $color-white;
        display: grid;
        grid-column: 2/3;
        grid-template-rows: repeat(4, min-content);
        justify-items: start;
        padding: 6.4rem 2.35rem 0 0.8rem;
        row-gap: 1.6rem;

        @include media-query-phone {
            $z-index: 100;
            $background-color: unset;

            background-color: $background-color;
            grid-column: 1/3;
            grid-row: 1/2;
            z-index: $z-index;
        }

        @include media-query-phone {
            padding-right: 0.8rem;
        }
    }

    &__slogan {
        color: $color-wild-watermelon;
        margin-bottom: -1.4rem;
    }

    &__title {
        -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
        color: $color-midnight;
        display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
        -webkit-line-clamp: 1;
        line-clamp: 1;
        margin: 0;
        overflow: hidden;

        @include media-query-tablet-and-down {
            font-size: $heading-font-size-3;
            -webkit-line-clamp: 2;
            line-clamp: 2;
        }
    }

    &__info {
        -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
        color: $color-slate-gray;
        display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
        -webkit-line-clamp: 4;
        line-clamp: 4;
        margin: 0;
        overflow: hidden;

        @include media-query-phone {
            color: $color-midnight;
        }

        @include media-query-tablet {
            font-size: $text-small;
        }
    }

    &__link {
        @include action-transition;

        background-color: $color-wild-watermelon;
        border-radius: 6.5rem;
        color: $color-white;
        font-size: $text-small;
        font-weight: $font-weight-medium;
        line-height: 1.7143;
        padding: 0.8rem 2.4rem;
        text-decoration: none;
        text-transform: uppercase;
    }
}
