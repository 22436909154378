.card-media {
    height: 0;
    padding-bottom: 100%; // ensures 1:1 aspect ratio, for other use [height / width * 100%]
    position: relative;
    width: 100%;

    img,
    video {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
