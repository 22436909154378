@import '../../styles/mixins/mixins';

.reviews-widget {
    grid-column: 3/4;
    grid-row: 2/4;
    margin: 4.5rem 0 0 -1rem;
    overflow-y: scroll;
    padding: 1rem 1rem 2.4rem;

    @include media-query-tablet-and-down {
        grid-column: 1/2;
        grid-row: 4/5;
        margin: 4.5rem 0 0;
        overflow-y: unset;
        padding: 0;
    }

    &__title {
        margin: 0 0 2.4rem;
    }

    &__content {
        list-style: none;
        margin: 0;
        padding: 0 0 0.8rem;

        @include media-query-tablet-and-down {
            margin-top: 1rem;
        }
    }
}
