@import '../../styles/base/variables';

.comment {
    &:not(:last-child) {
        padding-bottom: 1.2rem;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.4rem;
    }

    &__date {
        font-weight: $font-weight-regular;
    }

    &__text {
        font-size: $text-micro;
        line-height: 1.5;
        margin: 0;
        word-break: break-all;
        word-wrap: break-word;
    }
}
