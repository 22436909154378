$root-font-size: 62.5%;
$body-font-size-base: $text-medium;

html {
    font-size: $root-font-size;
}

body {
    background-color: $main-background;
    box-sizing: border-box;
    font-family: $font-family-base;
    font-size: $body-font-size-base;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
