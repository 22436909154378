@import '../../styles/base/variables';

.comment-input {
    align-items: center;
    display: flex;
    width: 100%;

    &__image {
        background: $color-slate-gray;
        border-radius: 50%;
        height: 2.4rem;
        width: 2.4rem;
    }

    &__textarea {
        border: 0;
        color: $color-text-700;
        flex: 1 0 auto;
        height: 2.4rem;
        line-height: 1.5;
        outline-offset: -0.4rem;
        overflow: hidden;
        padding: 0.5rem 1.2rem;
        resize: none;

        &::placeholder {
            color: $color-gray-chateau;
            font-size: $text-micro;
            line-height: 2.4;
        }

        &:focus {
            outline: 0.1rem solid $color-primary;
        }

        /* stylelint-disable-next-line */
        &::-moz-placeholder {
            transform: translateY(50%);
        }
    }

    &__submit {
        background: none;
        border: 0;
        color: $color-text-600;
        cursor: pointer;
        padding: 0;
    }
}
