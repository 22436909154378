@import '../../styles/base/variables';
@import '../../styles/mixins/mixins';

.weather-widget {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    grid-column: 2/3;
    justify-self: end;
    max-width: 52.8rem;
    width: 100%;

    @include media-query-phone() {
        grid-column: 1/2;
        justify-self: unset;
        max-width: none;
    }

    &__card {
        background-color: $card-background;
        border-radius: 1rem;
        box-shadow: $box-shadow-base-l;
        display: inline-flex;
        flex-direction: column;
        gap: 4.8rem;
        padding: 0.8rem 1.6rem;
        width: 100%;

        @include media-query-tablet {
            gap: 2rem;
        }

        @include media-query-phone {
            gap: 1.4rem;
        }
    }

    &__weather-data {
        display: flex;
        flex-direction: column;
    }

    &__location-date {
        color: $color-black;
        display: flex;
        flex-wrap: wrap;
        font-size: $text-xsmall;
        letter-spacing: 0.1rem;
        line-height: 1.1667;
        opacity: 50%;
        padding-right: 4.5rem;

        @include media-query-tablet-and-down {
            flex-direction: column;
            gap: 0.4rem;
        }
    }

    &__location-date-divider {
        margin: 0 0.4rem;

        @include media-query-tablet-and-down {
            display: none;
        }
    }

    &__weather-base {
        align-items: flex-end;
        display: flex;
        gap: 1.6rem;
        margin-bottom: 0.8rem;
        position: relative;

        @include media-query-phone {
            align-items: flex-start;
            flex-direction: column;
            gap: 0;

            &::after {
                width: 100%;
            }
        }

        &::after {
            background-color: $color-black;
            bottom: 0;
            content: '';
            height: 0.1rem;
            left: 0;
            opacity: 30%;
            position: absolute;
            width: 50%;

            @include media-query-phone {
                width: 100%;
            }
        }
    }

    &__weather-temperature {
        $text-xxxlarge: 6rem; // 60px

        color: $color-black;
        font-size: $text-xxxlarge;
        font-weight: $font-weight-light;
        line-height: 1.1667;
        opacity: 70%;
    }

    &__weather-condition {
        color: $color-black;
        font-size: $text-large;
        font-weight: $font-weight-light;
        line-height: 1.15;
        margin-right: 10rem;
        opacity: 50%;
        padding-bottom: 0.8rem;

        @include media-query-phone {
            margin-right: 0;
        }

        &::first-letter {
            text-transform: capitalize;
        }
    }

    &__weather-statistics {
        display: flex;
        flex-direction: row;
        gap: 3.2rem;

        @include media-query-phone {
            gap: 1.6rem;
        }
    }

    &__stat {
        align-items: flex-end;
        color: $color-black;
        display: flex;
        flex-direction: row;
        gap: 0.6rem;
        line-height: 1.1667;

        @include media-query-phone {
            gap: 0.4rem;
        }
    }

    &__stat-icon {
        height: 1.6rem;
        opacity: 30%;
        stroke: $color-black;
        width: 1.6rem;
    }

    &__stat-text {
        color: $color-black;
        font-size: $text-xsmall;
        font-weight: $font-weight-light;
        opacity: 50%;
    }

    &__illustration {
        align-items: flex-start;
        display: flex;
        width: 12.3rem;

        @include media-query-tablet {
            width: 8.2rem;
        }

        @include media-query-phone {
            margin-top: 2rem;
            width: 6.15rem;
        }
    }

    &__weather-icon {
        display: flex;
        margin-left: -12.3rem;

        @include media-query-tablet {
            margin-left: -8.2rem;
            width: 16.4rem;
        }

        @include media-query-phone {
            margin-left: -6.15rem;
            width: 12.3rem;
        }

        svg {
            width: 24.6rem;

            @include media-query-tablet {
                width: 16.4rem;
            }

            @include media-query-phone {
                width: 12.3rem;
            }
        }
    }
}
