@mixin respond-to($size, $dir) {
    @if ($size == retina) {
        // stylelint-disable media-feature-name-no-vendor-prefix
        @media only screen and (min-resolution: 2dppx),
            (-webkit-min-device-pixel-ratio: 2) {
            @content;
        }
    } @else if ($size and $dir) {
        @media screen and (#{$dir}-width: ($size + px)) {
            @content;
        }
    }
}

//media query mixins
@mixin media-query-phone {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin media-query-tablet {
    @media (min-width: 768px) and (max-width: 1023px) {
        @content;
    }
}

@mixin media-query-tablet-and-down {
    @media (max-width: 1023px) {
        @content;
    }
}

@mixin media-query-desktop {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin action-transition {
    transition: box-shadow 0.25s, transform 0.25s;

    &:hover {
        box-shadow: $box-shadow-base-sm;
        transform: translateY(-0.2rem);
    }

    &:active {
        box-shadow: $box-shadow-base-m;
        transform: translateY(0);
    }
}

//Media query for eat-out("Discover near you", "New place") and restaurant page "Also could like" sliders
@mixin three-slide-screen {
    @media screen and (max-width: 1440px) {
        @content;
    }
}
@mixin two-slide-screen {
    @media screen and (max-width: 1095px) {
        @content;
    }
}
@mixin one-slide-screen {
    @media screen and (max-width: 680px) {
        @content;
    }
}
