@import '../styles/mixins/mixins';

.restaurant {
    display: grid;
    gap: 4.8rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: min-content 22.1rem 46.5rem min-content;
    margin: -2.75rem 0 4.8rem;

    @include media-query-tablet-and-down {
        column-gap: unset;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, min-content);
        row-gap: 2.4rem;
    }

    @include media-query-phone {
        margin: -4.1rem 0 2.4rem;
    }

    &__reviews {
        grid-column: 3/4;
        grid-row: 2/3;
        margin: 0;

        @include media-query-tablet-and-down {
            grid-column: 1/2;
            grid-row: 4/5;
        }
    }
}
