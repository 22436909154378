@import '../../styles/base/variables';
@import '../../styles/mixins/mixins';

.dot-container {
    display: flex;
    gap: 1rem;
    padding-left: 0.8rem;

    &__dot {
        $dot-background-color: #c4c4c4;

        background-color: $dot-background-color;
        border: unset;
        border-radius: 50%;
        cursor: pointer;
        height: 0.6rem;
        margin: auto;
        padding: 0;
        transition: background-color 0.25s, height 0.25s, width 0.25s;
        width: 0.6rem;

        @include media-query-phone {
            background-color: $color-fiord;
        }

        &--active {
            background-color: $color-wild-watermelon;
            height: 0.8rem;
            width: 0.8rem;
        }
    }
}
