@import '../../styles/base/variables';

.social-button-group {
    align-items: center;
    display: flex;
    height: 2.4rem;
    justify-content: space-between;
    margin: 0 1.2rem 0 0;
    width: 4.4rem;

    &--comment {
        width: 3.2rem;
    }
}

.counter {
    color: $color-text-400;
}
