@import '../../styles/base/variables';
@import '../../styles/mixins';

.form-container {
    @mixin mobile-any {
        @include mobile-portrait() {
            @content;
        }
        @include mobile-landscape() {
            @content;
        }
    }

    @mixin mobile-portrait {
        @media only screen and (max-width: 767px) and (orientation: portrait) {
            @content;
        }
    }

    @mixin mobile-landscape {
        @media only screen and (max-height: 767px) and (orientation: landscape) {
            @content;
        }
    }

    // iPad, iPad Mini, iPad Air, Nest Hub Max
    @mixin special-devices {
        @media only screen and (min-height: 768px) and (max-height: 820px) and (orientation: landscape) and (min-resolution: 2dppx) {
            @content;
        }
    }

    align-items: center;
    background-color: $color-mercury;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    &__wrapper {
        position: relative;

        @include mobile-any() {
            display: contents;
        }
    }

    &__card-logo {
        left: 50%;
        margin-left: -81.5px;
        position: absolute;
        top: -14.95rem;

        @include mobile-any() {
            left: 0;
            margin-left: 0;
            position: relative;
            top: 0;
        }

        @include special-devices() {
            top: -10.35rem;
        }
    }

    &__top-container {
        @include mobile-any() {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
    }

    &__card {
        background-color: $card-background;
        border-radius: 0.8rem;
        box-shadow: $box-shadow-base-sm;
        box-sizing: border-box;
        color: $color-text-700;
        overflow-y: auto;
        padding: 4rem 3.2rem;

        @include mobile-any() {
            border-radius: unset;
            box-shadow: unset;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            padding: 4rem 2rem;
            width: 100%;
        }

        header {
            h2.alt {
                margin: 0 0 0.8rem;
            }

            p {
                margin: 0;
            }
        }

        main {
            padding: 3.2rem 0;
        }

        footer button {
            align-self: center;
        }
    }
}
